<template>
  <div v-if="locationsPageState" class="page-content has-padding">
    <ModalCreateLocation
      v-if="showCreateLocation"
      :showModal="showCreateLocation"
      :onClickCancel="hideModal"
    />

    <ModalAddLocation
      v-if="showAddLocation"
      :showModal="showAddLocation"
      :onClickCancel="hideModal"
    />

    <ModalDeactivateLocation
      v-if="showDeactivateLocation"
      :showModal="showDeactivateLocation"
      :location="selectedLocation"
      :onClickCancel="hideModal"
      @locationDeactivated="locationDeactivated"
    />

    <ModalDeleteLocationChannel
      v-if="showDeleteLocation"
      :showModal="showDeleteLocation"
      :location="selectedLocation"
      :onClickCancel="hideModal"
    />

    <ui-page-hero title="Locations" />

    <section class="section is-small">
      <div class="tile is-ancestor has-text-centered">
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title is-size-2">
              <AnimatedNumber :value="totals.NrOfLocations" :speed="5" />
            </p>
            <p class="subtitle is-size-6">Total locations</p>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title is-size-2">
              <AnimatedNumber :value="totals.TotalNew" :speed="5" />
            </p>
            <p class="subtitle is-size-6">Pending locations</p>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title is-size-2">
              <AnimatedNumber :value="totals.TotalPending" :speed="5" />
            </p>
            <p class="subtitle is-size-6">Requesting locations</p>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title is-size-2">
              <AnimatedNumber :value="totals.TotalActive" :speed="5" />
            </p>
            <p class="subtitle is-size-6">Active locations</p>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title is-size-2">
              <AnimatedNumber :value="totals.TotalInActive" :speed="5" />
            </p>
            <p class="subtitle is-size-6">Inactive locations</p>
          </article>
        </div>

        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title is-size-2">
              <AnimatedNumber :value="totals.TotalPublished" :speed="5" />
            </p>
            <p class="subtitle is-size-6">Published</p>
          </article>
        </div>
      </div>
    </section>

    <div class="tabs">
      <ul>
        <li :class="{ 'is-active': showLocationsList }">
          <a @click="setShowPage('locations')">Locations</a>
        </li>
        <li :class="{ 'is-active': showNotesList }">
          <a @click="setShowPage('notes')">Notes ({{ nrOfNotes }})</a>
        </li>
        <li :class="{ 'is-active': showToDosList }">
          <a @click="setShowPage('todos')">To-dos ({{ nrOfOpenToDos }})</a>
        </li>
      </ul>
    </div>

    <!-- LOCATIONS LIST -->
    <div v-if="showLocationsList">
      <section class="section is-small">
        <div
          class="actionBar has-background-white-ter has-padding has-margin-bottom"
        >
          <div class="level">
            <div class="level-left"></div>
            <div class="level-right">
              <a @click="setShowAddLocation">
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
                <span>Add existing location</span>
              </a>
              <a @click="setShowCreateLocation">
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </span>
                <span>Create new location</span>
              </a>
            </div>
          </div>
        </div>

        <div class="navbar-menu">
          <div class="navbar-start">
            <span class="select is-small">
              <select
                @change="setCountryId"
                v-model="locationsPageState.countryId"
              >
                <option
                  value="0"
                  :selected="locationsPageState.countryId === 0"
                >
                  All countries
                </option>
                <option
                  v-for="country in countries"
                  :key="country.Id"
                  :value="country.Id"
                  :selected="locationsPageState.countryId === country.Id"
                >
                  {{ country.Name }}
                </option>
              </select>
            </span>

            <span class="select is-small has-margin-left">
              <select
                @change="activationStatusChanged"
                v-model="locationsPageState.activationStatus"
              >
                <option
                  :value="-2"
                  :selected="locationsPageState.activationStatus === -2"
                >
                  All activation status
                </option>
                <option
                  :value="-1"
                  :selected="locationsPageState.activationStatus === -1"
                >
                  Denied
                </option>
                <option
                  :value="0"
                  :selected="locationsPageState.activationStatus === 0"
                >
                  Pending
                </option>
                <option
                  :value="1"
                  :selected="locationsPageState.activationStatus === 1"
                >
                  Request
                </option>
                <option
                  :value="2"
                  :selected="locationsPageState.activationStatus === 2"
                >
                  Activated
                </option>
                <option
                  :value="3"
                  :selected="locationsPageState.activationStatus === 3"
                >
                  Stopped
                </option>
              </select>
            </span>

            <span class="select is-small has-margin-left">
              <select
                @change="publishStatusChanged"
                v-model="locationsPageState.publishStatus"
              >
                <option
                  :value="-1"
                  :selected="locationsPageState.publishStatus === -1"
                >
                  All publish status
                </option>
                <option
                  :value="1"
                  :selected="locationsPageState.publishStatus === 1"
                >
                  Published
                </option>
                <option
                  :value="0"
                  :selected="locationsPageState.publishStatus === 0"
                >
                  Non published
                </option>
              </select>
            </span>
          </div>

          <div class="navbar-end">
            <div class="field has-addons">
              <input
                type="text"
                v-model="locationsPageState.searchTerm"
                v-on:keyup.13="searchLocations"
                class="input is-small"
                placeholder="Search locations"
              />
              <button
                class="button is-primary is-small"
                :class="{ 'is-loading': isLocationLoading }"
                @click="searchLocations"
              >
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>

        <table class="table is-fullwidth is-striped has-margin-top">
          <thead>
            <tr>
              <th>#</th>
              <th>Published</th>
              <th>Location status</th>
              <th>Name</th>
              <th>City</th>
              <th>Phone</th>
              <th class="has-text-right">Joined on</th>
              <th>&nbsp;</th>
            </tr>
          </thead>

          <transition-group
            name="staggered-fade"
            v-bind:css="false"
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            tag="tbody"
          >
            <tr
              v-for="(location, index) in locationsPageState.locations"
              :key="'tr_' + index"
              :data-index="index"
            >
              <td>{{ location.Id }}</td>
              <td width="50">
                <a @click="setLocationChannelActiveState(location)">
                  <span class="icon">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      class="has-text-success"
                      :key="'icon_active'"
                      v-if="location.LocationChannelActive"
                    />
                    <font-awesome-icon
                      :icon="['fas', 'times']"
                      class="has-text-danger"
                      :key="'icon_inactive'"
                      v-if="!location.LocationChannelActive"
                    />
                  </span>
                </a>
              </td>
              <td>
                <div class="tags">
                  <span
                    v-if="
                      !location.Locked &&
                      location.ActivationStatus === 'Activated'
                    "
                    class="tag is-success"
                    >Active</span
                  >
                  <span
                    v-if="
                      !location.Locked && location.ActivationStatus === 'Denied'
                    "
                    class="tag is-warning"
                    >Denied</span
                  >
                  <span
                    v-if="
                      !location.Locked &&
                      location.ActivationStatus === 'Neutral'
                    "
                    class="tag"
                    >Pending</span
                  >
                  <span
                    v-if="
                      !location.Locked &&
                      location.ActivationStatus === 'Requested'
                    "
                    class="tag is-info"
                    >Request</span
                  >
                  <span v-if="location.Locked" class="tag is-danger"
                    >Locked</span
                  >
                  <span
                    v-if="
                      !location.Locked &&
                      location.ActivationStatus === 'Stopped'
                    "
                    class="tag is-dark"
                    >Stopped</span
                  >
                </div>
              </td>
              <td>
                <a @click="goToLocation(location)">{{ location.Name }}</a>
              </td>
              <td>{{ location.City }}</td>
              <td>{{ location.PhoneNumber }}</td>
              <td class="has-text-right">
                {{ location.CreatedOn | convertTimeStampToIsoDateTimeString }}
              </td>
              <td width="75">
                <span class="field is-grouped has-text-right">
                  <span class="control">
                    <a @click="goToLocation(location)" class="is-small">
                      <span class="icon">
                        <font-awesome-icon :icon="['fas', 'edit']" />
                      </span>
                    </a>
                  </span>

                  <span class="control">
                    <a
                      @click="setShowDeleteLocation(location)"
                      class="is-small"
                    >
                      <span class="icon has-text-danger">
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                      </span>
                    </a>
                  </span>
                </span>
              </td>
            </tr>
          </transition-group>
        </table>

        <transition name="fade" mode="out-in">
          <div v-if="isLocationLoading">
            <ui-loader />
          </div>
        </transition>

        <Pagination
          v-if="
            (!isLocationLoading && locationsPageState.morePages > 0) ||
            locationsPageState.page > 1
          "
          :currentPage="locationsPageState.page"
          :morePages="locationsPageState.morePages"
          @pageSelected="pageSelected"
        />
      </section>
    </div>

    <!-- LOCATION NOTES -->
    <div v-if="showNotesList">
      <Notes
        :notes="notes"
        :getFresh="false"
        :showLink="true"
        :showItemName="true"
        :showNoNotesMessage="!isNoteLoading"
      />

      <Pagination
        v-if="(!isNoteLoading && notesResult.MorePages > 0) || notePage > 1"
        :currentPage="notePage"
        :morePages="notesResult.MorePages"
        @pageSelected="notePageSelected"
      />
    </div>

    <!-- LOCATION TODOS -->
    <div v-if="showToDosList">
      <ToDos
        :todos="todos"
        :getFresh="false"
        :showLink="true"
        :showItemName="true"
        :workEnvironment="'Channel'"
        :environmentId="channelId"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import channelProvider from '@/providers/channel'
import countryProvider from '@/providers/country'
import locationProvider from '@/providers/location'
import noteProvider from '@/providers/note'
import statsProvider from '@/providers/stats'
import todoProvider from '@/providers/todo'

export default {
  components: {
    AnimatedNumber: () => import('@/components/UI/AnimatedNumber'),
    ModalAddLocation: () => import('@/components/Locations/ModalAddLocation'),
    ModalCreateLocation: () =>
      import('@/components/Locations/ModalCreateLocation'),
    ModalDeactivateLocation: () =>
      import('@/components/Locations/ModalDeactivateLocation'),
    ModalDeleteLocationChannel: () =>
      import('@/components/Locations/ModalDeleteLocationChannel'),
    Pagination: () => import('@/components/UI/Pagination'),
    Notes: () => import('@/components/Notes/Notes'),
    ToDos: () => import('@/components/ToDos/ToDos'),
  },

  data() {
    return {
      countries: [],
      channelId: Number(this.$route.params.channelId),
      isNoteLoading: false,
      isLocationLoading: false,
      packages: [],
      selectedLocation: null,
      showAddLocation: false,
      showCreateLocation: false,
      showDeactivateLocation: false,
      showDeleteLocation: false,
      totals: {
        NrOfLocations: 0,
        TotalNew: 0,
        TotalPending: 0,
        TotalActive: 0,
        TotalInActive: 0,
        TotalPublished: 0,
      },

      showLocationsList: true,
      showNotesList: false,
      showToDosList: false,
      notesResult: null,
      notePage: 1,
      todos: [],
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationsPageState']),

    nrOfNotes() {
      let nr = 0
      if (this.notesResult && this.notesResult.Results.length > 0) {
        nr = this.notesResult.Results.length
      }
      return nr
    },

    notes() {
      let notes = []
      if (this.notesResult && this.notesResult.Results.length > 0) {
        notes = this.notesResult.Results
      }
      return notes
    },

    nrOfOpenToDos() {
      let nr = 0
      if (this.todos && this.todos.length > 0) {
        nr = this.todos.filter((t) => !t.IsDone).length
      }
      return nr
    },
  },

  created() {
    this.getCountries()
    this.getLocations()
    this.getLocationTotals()
    this.getChannelLocationNotes()
    this.getChannelLocationToDos()
  },

  beforeDestroy() {},

  methods: {
    ...mapMutations('locationStore', ['setLocation']),
    ...mapMutations('locationStore', ['setLocationsPageState']),

    getCountries() {
      countryProvider.methods
        .getActiveCountries(this.channelId, true)
        .then((response) => {
          this.countries = response.data
        })
        .catch((err) => {})
    },

    getLocations() {
      let self = this

      if (!self.reachedEnd) {
        this.isLocationLoading = true

        this.locationsPageState.channelId = this.channelId

        locationProvider.methods
          .getLocations(
            self.locationsPageState.channelId,
            self.locationsPageState.searchTerm,
            self.locationsPageState.page,
            self.locationsPageState.itemsPerPage,
            self.locationsPageState.activationStatus,
            self.locationsPageState.publishStatus,
            self.locationsPageState.countryId
          )
          .then((response) => {
            self.isLocationLoading = false

            if (response.data.MorePages === 0) {
              self.locationsPageState.reachedEnd = true
            }

            self.locationsPageState.morePages = response.data.MorePages
            self.locationsPageState.locations = response.data.Results

            self.setLocationsPageState(self.locationsPageState)
          })
          .catch((err) => {})
      }
    },

    getLocationTotals() {
      let self = this

      statsProvider.methods
        .getLocationTotalsStats(
          self.locationsPageState.channelId,
          self.locationsPageState.countryId
        )
        .then((response) => {
          if (response.status === 200) {
            self.totals.NrOfLocations = response.data.NrOfLocations
            self.totals.TotalNew = response.data.TotalNew
            self.totals.TotalPending = response.data.TotalPending
            self.totals.TotalActive = response.data.TotalActive
            self.totals.TotalInActive = response.data.TotalInActive
            self.totals.TotalPublished = response.data.TotalPublished
          }
        })
        .catch((err) => {})
    },

    pageSelected(page) {
      let self = this
      self.locationsPageState.locations = []
      self.locationsPageState.reachedEnd = false
      self.locationsPageState.page = page

      self.getLocations()
    },

    searchLocations() {
      this.page = 1
      this.locationsPageState.reachedEnd = false

      this.locationsPageState.page = 1
      this.locationsPageState.locations = []

      this.setLocationsPageState(this.locationsPageState)

      this.getLocations()
    },

    notePageSelected(page) {
      let self = this
      self.notesResult.Results = []
      self.reachedEnd = false
      self.notePage = page
      self.timestamp = new Date().getTime()

      self.getChannelLocationNotes()
    },

    getChannelLocationNotes() {
      if (!this.isNoteLoading) {
        this.isNoteLoading = true
        noteProvider.methods
          .getChannelNotes(this.channelId, 'Location', 0, this.notePage, 25)
          .then((response) => {
            if (response.status === 200) {
              this.notesResult = response.data
            }
          })
          .catch((err) => {})
          .finally(() => {
            this.isNoteLoading = false
          })
      }
    },

    getChannelLocationToDos() {
      todoProvider.methods
        .getChannelToDos(this.channelId, 'Location', 0, 1, 25)
        .then((response) => {
          if (response.status === 200) {
            this.todos = response.data.Results
          }
        })
        .catch((err) => {})
    },

    activationStatusChanged() {
      let self = this
      self.isLocationLoading = false

      self.locationsPageState.page = 1
      self.locationsPageState.bottom = false
      self.locationsPageState.reachedEnd = false
      self.locationsPageState.locations = []

      self.setLocationsPageState(self.locationsPageState)

      self.getLocations()
      self.getLocationTotals()
    },

    publishStatusChanged() {
      let self = this
      self.isLocationLoading = false

      self.locationsPageState.page = 1
      self.locationsPageState.bottom = false
      self.locationsPageState.reachedEnd = false
      self.locationsPageState.locations = []

      self.setLocationsPageState(self.locationsPageState)

      self.getLocations()
      self.getLocationTotals()
    },

    setCountryId() {
      let self = this
      self.isLocationLoading = false

      self.locationsPageState.page = 1
      self.locationsPageState.bottom = false
      self.locationsPageState.reachedEnd = false
      self.locationsPageState.morePages = 0
      self.locationsPageState.locations = []

      self.setLocationsPageState(self.locationsPageState)
      self.getLocations()
      self.getLocationTotals()
    },

    goToLocation(location) {
      this.setLocation(location)

      this.$router.push({
        name: `locations-detail`,
        params: { channelId: this.channel.Id, locationId: location.Id },
      })
    },

    setLocationChannelActiveState(location) {
      if (location.LocationChannelActive) {
        //this.deactivateLocationChannel(location)
        this.selectedLocation = location
        this.showDeactivateLocation = true
      } else {
        this.activateLocationChannel(location)
      }
    },

    activateLocationChannel(location) {
      let self = this

      channelProvider.methods
        .activateLocationChannel(self.channelId, location.Id)
        .then((response) => {
          if (response.status === 200) {
            let lps = JSON.parse(JSON.stringify(self.locationsPageState))

            let locationIndex = lps.locations.findIndex(
              (l) => l.Id === location.Id
            )

            if (locationIndex > -1) {
              location.LocationChannelActive = true

              Vue.set(lps.locations, locationIndex, location)

              self.setLocationsPageState(lps)

              self.getLocationTotals()
            }
          }
        })
    },

    locationDeactivated(location) {
      let self = this

      let lps = JSON.parse(JSON.stringify(self.locationsPageState))

      let locationIndex = lps.locations.findIndex((l) => l.Id === location.Id)

      if (locationIndex > -1) {
        location.LocationChannelActive = false

        Vue.set(lps.locations, locationIndex, location)

        self.setLocationsPageState(lps)

        self.getChannelLocationNotes()
      }
    },

    setShowPage(item) {
      this.showLocationsList = item === 'locations'
      this.showNotesList = item === 'notes'
      this.showToDosList = item === 'todos'
    },

    setShowAddLocation() {
      this.showAddLocation = true
    },

    setShowCreateLocation() {
      this.showCreateLocation = true
    },

    setShowDeleteLocation(location) {
      this.selectedLocation = location
      this.showDeleteLocation = true
    },

    hideModal() {
      this.selectedLocation = null

      this.showAddLocation = false
      this.showCreateLocation = false
      this.showDeleteLocation = false
      this.showDeactivateLocation = false
      this.showDeleteLocation = false

      this.getLocationTotals()
    },

    beforeEnter: function (el) {
      el.style.opacity = 0
    },
    enter: function (el, done) {
      var delay = el.dataset.index * 50
      setTimeout(function () {
        el.style.transition = 'opacity 0.3s'
        el.style.opacity = '1'
      }, delay)
    },
    leave: function (el, done) {
      var delay = el.dataset.index * 50
      setTimeout(function () {
        el.style.transition = 'opacity 0.3s'
        el.style.opacity = '0'
      }, delay)
    },
    afterLeave: function (el) {
      if (el) {
        el.parentNode.removeChild(el)
      }
    },
  },
}
</script>
